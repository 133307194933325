export default {
  //BOTTOM NAV
  home: "Início",
  buybye: "BuyBye",
  account: "Conta",
  purchaseList: "Lista de Compras",
  //HOME
  openBuyBye: "Abrir o BuyBye",
  // CABINET
  openBuyByeTitle: "Bem-vindo ao Go Natural & Go.",
  openBuyByeSubtitle: "Insira o código para comprar.",
  openBuyByeText: "Para comprar na Noweat precisas de ter dinheiro no teu cartão. Fazemos uma pré-autorização quando abre a porta",
  // receipts
  purchaseLabel: "Compra",
  receiptSubtitle: (value) => {
    return `Comprou ${value} items.`;
  },
  receiptDuration: (value) => {
    return `A sua compra demorou <strong>${value}s.</strong>`;
  },
  // close door page
  closeDoor: (expired) => {
    return `Obrigado pela sua compra.<p>Iremos o processar o seu pagamento.</p><p>${expired}</p>`;
  },
  //Cases List
  casesListLabel: "Suporte ao Cliente",
  casesListItensCase: "Caso",
  casesListItensCreatedAt: "Criado em",
  casesListItensMachine: "BuyBye",
  casesListItensStatus: "Estado",
  casesListNoItens: "Não tem nenhum caso.",
  casesListItensStatusTrad: {
    Open: "Aberto",
    Closed: "Fechado",
    "In Resolution": "Em resolução",
  },
  // verify
  verify: { title: "Código de Validação" },
  // help form
  helpForm: {
    title: "Feedback Cliente",
    mentionId: "Código Máquina ou ID da compra",
    subject: "Assunto",
    description: { label: "Descrição", placeholder: "Escreva a sua mensagem" },
    contactMethod: {
      lable: "Método de Contacto",
      phone: "Telefone",
      email: "Email",
    },
    email: "Email",
    phone: "Telefone",
  },
  //caseManagement
  caseManagement: {
    title: "Gestão de Casos",
    subtitle: {
      title: "Fale Connosco.",
      subtitle:
        "Preencha o formulário para ser contactado pela equipa de suporte.",
    },
  },
  // actions
  action: {
    sendMessage: "Enviar mensagem",
    setNewPassword: "Mudar Palavra Passe",
    getCode: "Obter Código",
  },
  actions: {
    sendMessage: "Enviar mensagem",
    setNewPassword: "Mudar Palavra Passe",
    getCode: "Obter Código",
  },

  //forgot Password
  forgot: {
    phoneNumber: "Insira o seu número de telefone.",
    helpTip: "Irá receber uma sms com o código de validação.",
  },
  // error
  error: {
    requiredFields: "Por favor preencha todos os campos.",
    validData: "Por favor preencha os campos com informação fidedigna.",
  },
  // global
  global: {
    email: "Email",
    name: "Nome",
    phone: "Telefone",
    username: "Nome de Utilizador",
    acceptTerms: "* Clicando estará a aceitar os",
    signInTip: "Eu já tenho uma conta! Login",
    vat: "NIF",
    logout: "Logout",
    pt: " Português",
    en: " Inglês",
    language: "Linguagem",
    password: "Palavra Passe",
    newPassword: "Nova Palavra Passe",
    signIn: "Login",
    forgotPassword: "Esqueçeu Palavra Passe?",
    signUp: "Não tem conta? Registe-se",
    signUpButton: "Registar",
    terms: "Termos e Condições",
    privacyPolicy: "Política de Privacidade",
    form: "Ao preencher este formulário está a aceitar os"
  },
  //payments
  payments: {
    title: "Cartões de Pagamento",
    noCards: "Registe o seu primeiro cartão.",
    expirationDate: "Data de expiração",
    modalTitle: "Registo de Cartão",
    epg: {
      body: `To guarantee the veracity of the card we will capture 1 AED on card
      registration. Don't worry, this amount will be returned after the
      operation. 
      <br></br>All card details are protected by 3D secure transaction
      provided by Etisalat&reg;.`,
    },
    stripe: {
      body: `Para garantir a boa utilização do seu cartão irá capturar 1 € no cartão
      registo. Não se preocupe, este valor será devolvido após uma operação.
      <br> </br> Todos os detalhes do cartão são protegidos pela Stripe&reg;.`,
    },
    stripe3DS: {
      body: `Para garantir a boa utilização do seu cartão irá capturar 1 € no cartão
      registo. Não se preocupe, este valor será devolvido após uma operação.
      <br> </br> Todos os detalhes do cartão são protegidos pela Stripe&reg;.`,
    },
  },
  //inShop
  inShop: {
    title: "Compra",
    text: {
      p1: "Pegue nos produtos que deseja.",
      p2: "Mantenha os produtos no local correcto.",
      p3: "Feche as portas para encerrar a sua compra.",
    },
  },
  // profile form
  profile: {
    title: "Perfil",
  },

  // debt form
  debt: {
    title: "Ups. Parece que tens um débito pendente.",
    body: (values) => {
      if (!values) {
        values = { total: "", currency: "" };
      }
      return `Para continuares a comprar no Smart Fridge Ponto tens de pagar o valor em falta ${values.total} ${values.currency} (referente a ${values.debts} recibos). Ao carregares em continuar estás a autorizar o pagamento em falta.`;
    },
    cancel: "Cancelar",
    continue: "Continuar",
  },
  paymentSet:{
    paymentSet: "O seu cartão foi salvo",
    text: "Agora que o seu perfil e cartão estão definidos, pode aproveitar dos nossos produtos.",
    openButton: "ABRIR O BUYBYE"
  },
  profileSet:{
    profileSet: "O seu perfil foi salvo",
    text: "Agora que o seu perfil está definido, adicione um cartão de crédito para conseguir comprar",
    addCreditCardButton: "ADICIONAR CARTÃO DE CRÉDITO",
    skip: "Ignorar"
  },
  creditCardForm: {
    cardNumber: "Número do cartão",
    cardHolder: "Titular do cartão",
    month: "Mês",
    year: "Ano",
    submitButton: "Salvar",
    expires: "Expira",
    fullName: "NOME COMPLETO"
  },
  close:{
    processPayment: "A processar o pagamento. Estará disponível na sua página de compras brevemente.",
    closeButton: "Fechar"
  },
  forgotPassword:{
    insertPhone: "Insere o número de telemóvel",
    phone: "Telemóvel",
    getValidationCodeButton: "Receber código de validação"
  },
  success:{
    success:"Sucesso",
    purchaseRequest:"Recebemos o seu pedido de compra;",
    touchShortly: "entraremos em contacto em breve!"
  }
};
