import axios from "axios";
import moment from "moment";
import RandExp from "randexp";
import BuyByeService from "./buybye-service";
import Config from "../config/config";
import {Buffer} from 'buffer';

const appDNS = () => {
  return Config.dns || "app.reckon.ai";
};
const API_URL =
  process.env.AUTH_SERVER ||
  "https://reckon-api-auth-per-qa.reckon.ai/app" ||
  "http://192.168.1.110:8081/app";
const LENGTH_SECRET = parseInt(process.env.LENGTH_SECRET) || 80;

const fetch = (method, url, data = undefined, h = {}) => {
  const headers = {
    secret: generateSecret().result,
    "reckon-origin": appDNS(),
    ...h,
  };

  if (data) {
    return axios({
      method: method,
      url: url,
      headers: headers,
      data,
    });
  } else {
    return axios({
      method: method,
      url: url,
      headers: headers,
    });
  }
};

//data: {username, phone, password, role: user}
const register = async (user) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...user },
    url: "user",
    method: "POST",
  });
  if (res.data.success) {
    localStorage.setItem(
      "_user",
      JSON.stringify({ ...user, idUser: res.data.result })
    );
  }
  return res.data;
};

//data: {phone || idUser; code;}
const validateUser = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "validate/user",
    method: "PUT",
  });
  if (res.data.success) {
    deleteToken("_user");
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...res.data.result.user,
        idUser: res.data.result.user._id,
      })
    );
  }
  return res;
};

//data: {phone || idUser}
const newValidationCode = (data) => {
  return fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "validate/user",
    method: "GET",
  });
};

//data: {phone || idUser}
const getGenericCode = async (data) => {
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "user/sms",
    method: "POST",
  });
  if (res.data.success) {
    localStorage.setItem("idValidation", JSON.stringify(res.data.result));
  }
  return res;
};

//data: {idValidation, validation}
const validateGenericCode = async (data) => {
  const idValidation = JSON.parse(localStorage.getItem("idValidation"));
  const res = await fetch("POST", API_URL + "/send", {
    data: { idValidation, ...data },
    url: "user/validate/sms",
    method: "GET",
  });
  if (res.data.success)
    localStorage.setItem("user", JSON.stringify(res.data.result.user));

  return res;
};

//data: {phone || idUser}
const userToken = async (data) => {
  const res = await fetch("GET", API_URL + "/send", {
    data: { ...data },
    url: "user/token",
    method: "GET",
  });
  if (res.data.success) {
    localStorage.setItem("mytkn", JSON.stringify(res.data.result));
  }
};

const validateUserToken = () => {
  return fetch("POST", API_URL + "/validate", {
    token: JSON.parse(localStorage.getItem("mytkn")),
  });
};

//data: {service || dns || idApp, data: {....}}
const encryptData = async (data) => {
  return await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "app/token",
    method: "GET",
  });
};

//data: {phone || idUser, new: {...}}
const updateUser = (data) => {
  let user = getCurrentUser();
  return fetch("POST", API_URL + "/send", {
    data: { new: { ...data }, idUser: user._id },
    url: "user",
    method: "put",
  });
};
const deleteToken = async (token) => {
  localStorage.removeItem(token);
};
//data: {phone || idUser, password}}
const login = async (data) => {
  deleteToken("authToken");

  localStorage.clear();
  localStorage.setItem("_user", JSON.stringify({ ...data }));
  const res = await fetch("POST", API_URL + "/send", {
    data: { ...data },
    url: "user/login",
    method: "GET",
  });

  if (res.data.success) {
    BuyByeService.setToken("locale", { locale: res.data.result.user.locale });
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...res.data.result.user,
        token: `${res.data.result.token}`,
      })
    );
    localStorage.setItem(
      "mytkn",
      JSON.stringify({ token: `${res.data.result.token}` })
    );
  }

  return res;
};

/*
const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};*/

const logout = () => {
  localStorage.clear();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getPrivateUser = () => {
  return JSON.parse(localStorage.getItem("_user"));
};

const successResult = (success, result) => {
  return { success, result };
};
const base64Encoded = (data) => {
  try {
    return successResult(
      true,
      new Buffer.from(data, "utf8").toString("base64")
    );
  } catch (e) {
    return successResult(false, "Error");
  }
};

const generateSecret = () => {
  function invertNumber(data) {
    try {
      if (data.length === 1) data = "0" + data;
      return data.split("").reverse().join("");
    } catch (e) {
      throw e;
    }
  }
  try {
    const date = moment().utc(false);
    let year = date.format("Y");
    let newYear = "";
    for (let i = 0; i < year.length; i++) {
      if (i % 2) newYear += year[i];
      else newYear += `[a-zA-Z]{${year[i]}}`;
    }
    const month = date.format("M");
    const day = date.format("D");
    const hour = invertNumber(date.format("H"));
    const minute = invertNumber(date.format("m"));
    const second = invertNumber(date.format("s"));
    let secret = new RandExp(
      `${newYear}:${hour}[a-zA-Z]{${month}}${minute}[a-zA-Z]{${day}}${second}`
    ).gen();
    secret += new RandExp(
      `[a-zA-Z]{${1}[a-zA-Z0-9]{${LENGTH_SECRET - secret.length - 1}`
    ).gen();
    return base64Encoded(secret);
  } catch (e) {
    return successResult(false, e);
  }
};

export default {
  register,
  fetch,
  login,
  logout,
  generateSecret,
  getCurrentUser,
  validateUser,
  validateUserToken,
  getGenericCode,
  encryptData,
  updateUser,
  newValidationCode,
  validateGenericCode,
  userToken,
  getPrivateUser,
};
