const config = {
  dns: "noweat.buybye.app",
  useLocale: false,
  buybyeURL: "https://reckon-api-buybye-qa.reckon.ai",
  changeLocale: false,
  paymentSystem:"stripe",
  stripeKEY:"pk_live_51MOfaSJWtUAnk95ENhkxFfbwwuLQXhcaKHY8pm6D5cE47kxl1MRrjKkjVVTzUqchf1FA4lcym0RgVK8mLbTZWqLy00YtNq3Uzs"
};

export default config;
